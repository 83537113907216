<template>
  <div>
    <v-alert
        v-if="error"
        dense
        type="error"
    >
      {{ error }}
    </v-alert>
    <v-overlay
      :value="displayOverlay"
    >
      <v-card width="400" max-width="100%" :loading="loadingNewArtiste">
        <v-card-title>Ajouter un artiste</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
                v-model="newArtiste.name.value"
                label="Nom"
                filled
                required
                :error="!!newArtiste.name.error"
                :error-messages="newArtiste.name.error"
            ></v-text-field>
            <v-text-field
                v-model="newArtiste.label.value"
                filled
                label="Label"
                class="shrink"
                :error="!!newArtiste.label.error"
                :error-messages="newArtiste.label.error"
            ></v-text-field>
            <v-text-field
                v-model="newArtiste.social_link.value"
                filled
                label="Url réseau sociaux"
                class="shrink"
                :error="!!newArtiste.social_link.error"
                :error-messages="newArtiste.social_link.error"
            ></v-text-field>
            <v-file-input
                v-model="fileToUpload"
                filled
                label="Image"
                prepend-icon="mdi-camera"
                class="shrink"
                :error="!!newArtiste.photo_id.error"
                :error-messages="newArtiste.photo_id.error"
            >
            </v-file-input>
          </v-form>
          <v-card-actions>
            <v-btn outlined @click="closeForm">
              Annuler
            </v-btn>
            <v-btn color="primary" @click="validForm">
              Ajouter
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-card class="pt-3">
      <v-card-actions>
        <v-btn class="primary" @click="displayOverlay = true">
          Ajouter un artiste
          <v-icon
              right
              dark
          >
            mdi-account-plus
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-data-table
          :headers="headers"
          :items="artistes"
          item-key="name"
          :search="search"
          :custom-filter="filter"
          :loading="loading"
          loading-text="Chargement... Merci de patienter"
          no-data-text="Aucune donnée..."
          no-results-text="Aucun résultat ne correspond à votre recherche."
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Rechercher par nom ou label"
              class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.photo_id="{ item }">
          <v-simple-checkbox
              v-model="item.photo_id"
              disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import api from '../services/API'

export default {
  name: 'Artistes',
  data() {
    return {
      displayOverlay: false,
      search: '',
      newArtiste: {
        photo_id: {
          value: null,
          error: null
        },
        name: {
          value: null,
          error: null
        },
        social_link: {
          value: null,
          error: null
        },
        label: {
          value: null,
          error: null
        },
      },
      fileToUpload: null,
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Label',
          align: 'start',
          sortable: true,
          value: 'label'
        },
        {
          text: 'Liens réseaux sociaux',
          align: 'start',
          sortable: false,
          value: 'social_link'
        },
        {
          text: 'Photo',
          align: 'start',
          sortable: false,
          value: 'photo_id'
        },
        {
          text: 'Actions',
          align: 'end',
          sortable: false,
          value: 'actions'
        },
      ],
      loadingNewArtiste: false,
      loading: false,
      error: false,
    }
  },
  computed: {
    ...mapState(['artistes'])
  },
  methods: {
    ...mapMutations({
      setArtistes: 'SET_ARTISTES',
      addArtiste: 'ADD_ARTISTE',
      deleteArtiste: 'DELETE_ARTISTE'
    }),
    closeForm() {
      this.displayOverlay = false
      this.resetForm()
    },
    resetForm() {
      for (const [key] of Object.entries(this.newArtiste)) {
        this.newArtiste[key].value = null
      }
      this.fileToUpload = null
    },
    resetError() {
      for (const [key] of Object.entries(this.newArtiste)) {
        this.newArtiste[key].error = null
      }
    },
    validForm() {
      this.resetError()
      if (this.newArtiste.name.value) {
        this.postForm()
      } else {
        this.newArtiste.name.error = 'Veuillez renseigner un nom valide.'
      }
    },
    async postForm() {
      this.loadingNewArtiste = true

      if (this.fileToUpload) {
        await this.postPhoto()
      }

      const formatedArtiste = {
        name: this.newArtiste.name.value,
      }

      if (this.newArtiste.photo_id.value) {
        formatedArtiste.photo_id = this.newArtiste.photo_id.value
      }
      if (this.newArtiste.label.value) {
        formatedArtiste.label = this.newArtiste.label.value
      }
      if (this.newArtiste.social_link.value) {
        formatedArtiste.social_link = this.newArtiste.social_link.value
      }

      api.artiste.postArtiste(formatedArtiste)
          .then((response) => {
            if (response.data.artist) {
              this.addArtiste(response.data.artist)
              this.loadingNewArtiste = false
              this.closeForm()
            }
          })
          .catch((error) => {
            console.log(error)
            this.loadingNewArtiste = false
          })
    },
    async postPhoto() {
      await api.media.postMedia(this.fileToUpload)
          .then(async (responseMedia) => {
            if (responseMedia.data.filename) {
              const formatedPhoto = {
                alt: this.newArtiste.name.value,
                url: `https://api.lezoo.ch/media/${responseMedia.data.filename}`
              }
              await api.photos.postPhoto(formatedPhoto)
                  .then((responsePhoto) => {
                    if (responsePhoto.data.photo) {
                      this.newArtiste.photo_id.value = responsePhoto.data.photo.id
                    }
                  })
                  .catch(() => {
                    this.newArtiste.photo_id.error = 'Problème lors de l\'upload de votre image.'
                    this.loadingNewArtiste = false
                  })
            }
          })
          .catch(() => {
            this.newArtiste.photo_id.error = 'Problème lors de l\'upload de votre fichier.'
            this.loadingNewArtiste = false
          })
    },
    filter(value, search, item) {
      if (search !== null && value !== null) {
        return item.name.includes(search) || item.label.includes(search)
      }
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    }
  },
  async mounted() {
    this.loading = true

    await api.artiste.getArtistes()
        .then((response) => {
          if (response.data.artists) {
            this.setArtistes(response.data.artists)
          }
          this.loading = false
        })
        .catch((error) => {
          this.error = error.data
          this.loading = false
        })
  }
}
</script>
